export default{
    "create":{
        "title": "Créez votre séjour",
        "check-date": {
            "label": "Date de Check-in/Check-out",
            "placeholder": "jj/mm/aaaa - jj/mm/aaaa",
        },
        "continue-button": "Créer et accéder au séjour",
    },
    "share": {
        "title": "Rejoignez notre séjour à {hotel}",
        "text": "Rejoignez-nous maintenant et profitez de tous les avantages de la WebApp pour tirer le meilleur parti de notre expérience"
    },
    "detail": {
        "title": "Mes séjours",
        "createBtn": "Créer un séjour",
        "editBtn": "Modifier le séjour"
    },
    "edit": {
        "title": "Modifier le séjour",
        "information-stay": "Informations sur le séjour",
        "input": {
            "medio-reserva": {
                "label": "Moyen de réservation",
                "placeholder": "Sélectionner le moyen de réservation",
                "n-room": "Chambre Nº"
            }
        },
        "calendar": "Calendrier",
        "guests": "Invités",
        "saveBtn": "Enregistrer",
        "share": "Partager le séjour",
        "guest": "Invité",
        "completeCheckin": "Compléter l'enregistrement",
        "waitingGuest": "En attente de l'accès de l'invité",
        "accessRoom": "Accéder au séjour",
        "active": "Actif",
        "pageWebHotel": "Site web de l'hôtel"
    }
}
