export default {
    "account": "My account",
    "greeting": "Hello, {name}!",
    "my_stays": {
        "title": "My stays",
        "subtitle_active": "Manage your stays",
        "subtitle_inactive": "Create your next stay"
    },
    "personal_info": {
        "title": "Personal information",
        "description": "Set up your personal information"
    },
    "language" : {
        "title": "Language",
        "description": "Select your default language"
    },
    "logout": "Log out",
    "share_stay": "Share Stay",
    "page_personal_info": {
        "title": "Personal Information",
        "change_photo": "Change photo",
        "change_password": "Change password",
        "btn_save": "Save",
        "form": {
            "name": {
                "label": "Name",
                "placeholder": "Enter your name"
            },
            "last_name": {
                "label": "Last Name",
                "placeholder": "Enter your last name"
            },
            "email": {
                "label": "Email",
                "placeholder": "Enter your email"
            },
            "number_phone": {
                "label": "Phone Number",
                "placeholder": "Enter your phone number"
            },
            "password": {
                "label": "Password",
                "placeholder": "Enter your password"
            }
        }
    }
};
