export default {
    "account": "Il mio account",
    "greeting": "Ciao, {name}!",
    "my_stays": {
        "title": "I miei soggiorni",
        "subtitle_active": "Gestisci i tuoi soggiorni",
        "subtitle_inactive": "Crea il tuo prossimo soggiorno"
    },
    "personal_info": {
        "title": "Informazioni personali",
        "description": "Configura le tue informazioni personali"
    },
    "language" : {
        "title": "Lingua",
        "description": "Seleziona la lingua predefinita"
    },
    "logout": "Disconnettersi",
    "share_stay": "Condividi Soggiorno",
    "page_personal_info": {
        "title": "Informazioni personali",
        "change_photo": "Cambia foto",
        "change_password": "Cambia password",
        "btn_save": "Salva",
        "form": {
            "name": {
                "label": "Nome",
                "placeholder": "Inserisci il tuo nome"
            },
            "last_name": {
                "label": "Cognome",
                "placeholder": "Inserisci il tuo cognome"
            },
            "email": {
                "label": "Email",
                "placeholder": "Inserisci la tua email"
            },
            "number_phone": {
                "label": "Numero di telefono",
                "placeholder": "Inserisci il tuo numero di telefono"
            },
            "password": {
                "label": "Password",
                "placeholder": "Inserisci la tua password"
            }
        }
    }
};
