export default{
    "create":{
        "title": "Create your stay",
        "check-date": {
            "label": "Check-in/Check-out Date",
            "placeholder": "dd/mm/yyyy - dd/mm/yyyy",
        },
        "continue-button": "Create and access the stay",
    },
    "share": {
        "title": "Join our stay at {hotel}",
        "text": "Join now and enjoy all the benefits of the WebApp to make the most of our experience"
    },
    "detail": {
        "title": "My stays",
        "createBtn": "Create stay",
        "editBtn": "Edit stay"
    },
    "edit": {
        "title": "Edit stay",
        "information-stay": "Stay information",
        "input": {
            "medio-reserva": {
                "label": "Booking method",
                "placeholder": "Select booking method",
                "n-room": "Room Nº"
            }
        },
        "calendar": "Calendar",
        "guests": "Guests",
        "saveBtn": "Save",
        "share": "Share stay",
        "guest": "Guest",
        "completeCheckin": "Complete check-in",
        "waitingGuest": "Waiting for guest access",
        "accessRoom": "Access stay",
        "active": "Active",
        "pageWebHotel": "Hotel website"
    }
}
