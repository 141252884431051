export default {
    "textToast": {
        "sendQueryText": "Agradecemos sinceramente o seu feedback. A sua experiência é importante para nós e esforçamo-nos para atender às suas expectativas.",
        "updateQueryText" : "Modificação enviada",
        "registerResponse": "Feedback enviado",
        "updateResponse": "Feedback modificado"
    },
    "form": {
        "send": "Enviar",
        "modify" : "Modificar",
        "cancel" : "Cancelar",
        "title": "Consultas",
        "hello": "Olá",
        "whatsup": "Como vai?",
        "thanksAll" : "Obrigado por tudo",
        "btn-verywrong-stay": "Muito ruim",
        "btn-wrong-stay": "Ruim",
        "btn-normal-stay": "Normal",
        "btn-good-stay": "Bom",
        "btn-verygood-stay": "Muito bom",
        "btn-verywrong-poststay": "Muito ruim",
        "btn-wrong-poststay": "Ruim",
        "btn-normal-poststay": "Normal",
        "btn-good-poststay": "Bom",
        "btn-verygood-poststay": "Muito bom",
        "poststay-bad-thanks-title": "Resposta enviada", 
        "poststay-bad-thanks-subtitle": "Muito obrigado pelo seu tempo",
        "continueIn" : "Continuar em"
    },
    "settings": {
        "questionpre-stay": "Há algo que possamos fazer para a sua chegada no hotel?",
        "questionin-stay": "Como você avaliaria seu nível de satisfação com a sua estadia até agora?",
        "questionpost-stay": "Como foi a sua experiência conosco?",
    },
    "inbox": {
        "title": "Mensagens"
    }
}
