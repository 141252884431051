export default{
    "create":{
        "title": "Erstelle deinen Aufenthalt",
        "check-date": {
            "label": "Check-in/Check-out Datum",
            "placeholder": "TT/MM/JJJJ - TT/MM/JJJJ",
        },
        "continue-button": "Erstellen und zum Aufenthalt zugreifen",
    },
    "share": {
        "title": "Begleiten Sie unseren Aufenthalt im {hotel}",
        "text": "Treten Sie jetzt bei und genießen Sie alle Vorteile der WebApp, um das Beste aus unserer Erfahrung zu machen"
    },
    "detail": {
        "title": "Meine Aufenthalte",
        "createBtn": "Aufenthalt erstellen",
        "editBtn": "Aufenthalt bearbeiten"
    },
    "edit": {
        "title": "Aufenthalt bearbeiten",
        "information-stay": "Aufenthaltsinformationen",
        "input": {
            "medio-reserva": {
                "label": "Buchungsmethode",
                "placeholder": "Buchungsmethode auswählen",
                "n-room": "Zimmer-Nr."
            }
        },
        "calendar": "Kalender",
        "guests": "Gäste",
        "saveBtn": "Speichern",
        "share": "Aufenthalt teilen",
        "guest": "Gast",
        "completeCheckin": "Check-in abschließen",
        "waitingGuest": "Warten auf Gastzugang",
        "accessRoom": "Aufenthalt betreten",
        "active": "Vermögenswert",
        "pageWebHotel": "Hotel-Website"
    }
}
