export default {
    "account": "Minha conta",
    "greeting": "Olá, {name}!",
    "my_stays": {
        "title": "Minhas estadias",
        "subtitle_active": "Gerencie suas estadias",
        "subtitle_inactive": "Crie sua próxima estadia"
    },
    "personal_info": {
        "title": "Informações pessoais",
        "description": "Configure suas informações pessoais"
    },
    "language" : {
        "title": "Preferência de idioma",
        "description": "Selecione seu idioma padrão"
    },
    "logout": "Sair",
    "share_stay": "Compartilhar Estadia",
    "page_personal_info": {
        "title": "Informações pessoais",
        "change_photo": "Alterar foto",
        "change_password": "Alterar senha",
        "btn_save": "Salvar",
        "form": {
            "name": {
                "label": "Nome",
                "placeholder": "Digite seu nome"
            },
            "last_name": {
                "label": "Sobrenome",
                "placeholder": "Digite seu sobrenome"
            },
            "email": {
                "label": "Email",
                "placeholder": "Digite seu email"
            },
            "number_phone": {
                "label": "Número de telefone",
                "placeholder": "Digite seu número de telefone"
            },
            "password": {
                "label": "Senha",
                "placeholder": "Digite sua senha"
            }
        }
    }
};
