export default {
    "account": "Mon compte",
    "greeting": "Bonjour, {name}!",
    "my_stays": {
        "title": "Mes séjours",
        "subtitle_active": "Gérez vos séjours",
        "subtitle_inactive": "Créez votre prochain séjour"
    },
    "personal_info": {
        "title": "Informations personnelles",
        "description": "Configurez vos informations personnelles"
    },
    "language" : {
        "title": "Langue",
        "description": "Sélectionnez votre langue par défaut"
    },
    "logout": "Se déconnecter",
    "share_stay": "Partager le Séjour",
    "page_personal_info": {
        "title": "Informations personnelles",
        "change_photo": "Changer de photo",
        "change_password": "Changer de mot de passe",
        "btn_save": "Enregistrer",
        "form": {
            "name": {
                "label": "Prénom",
                "placeholder": "Entrez votre prénom"
            },
            "last_name": {
                "label": "Nom",
                "placeholder": "Entrez votre nom"
            },
            "email": {
                "label": "Email",
                "placeholder": "Entrez votre email"
            },
            "number_phone": {
                "label": "Numéro de téléphone",
                "placeholder": "Entrez votre numéro de téléphone"
            },
            "password": {
                "label": "Mot de passe",
                "placeholder": "Entrez votre mot de passe"
            }
        }
    }
};
