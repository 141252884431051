export default {
    "account": "Mein Konto",
    "greeting": "Hallo, {name}!",
    "my_stays": {
        "title": "Meine Aufenthalte",
        "subtitle_active": "Verwalten Sie Ihre Aufenthalte",
        "subtitle_inactive": "Erstellen Sie Ihren nächsten Aufenthalt"
    },
    "personal_info": {
        "title": "Persönliche Informationen",
        "description": "Richten Sie Ihre persönlichen Informationen ein"
    },
    "language" : {
        "title": "Sprache",
        "description": "Wählen Sie Ihre Standardsprache aus"
    },
    "logout": "Abmelden",
    "share_stay": "Aufenthalt Teilen",
    "page_personal_info": {
        "title": "Persönliche Informationen",
        "change_photo": "Foto ändern",
        "change_password": "Passwort ändern",
        "btn_save": "Speichern",
        "form": {
            "name": {
                "label": "Vorname",
                "placeholder": "Geben Sie Ihren Vornamen ein"
            },
            "last_name": {
                "label": "Nachname",
                "placeholder": "Geben Sie Ihren Nachnamen ein"
            },
            "email": {
                "label": "E-Mail",
                "placeholder": "Geben Sie Ihre E-Mail ein"
            },
            "number_phone": {
                "label": "Telefonnummer",
                "placeholder": "Geben Sie Ihre Telefonnummer ein"
            },
            "password": {
                "label": "Passwort",
                "placeholder": "Geben Sie Ihr Passwort ein"
            }
        }
    }
};
