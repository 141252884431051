export default {
    "header": {
        "home": "início",
        "facilities": "instalações",
        "destination": "destino",
        "experiences": "experiências",
        "messages": "mensagens"
    },
    "dropdownLanguage": {
        "title": "idioma"
    },
    "footer": {
        "legal-warning": "aviso legal",
        "privacy-policy": "política de privacidade",
        "privacy-cookies": "política de cookies",
        "terms-conditions": "termos e condições"
    },
    "input-search": {
        "search": "Pesquisar",
        "placeholder": "Destinos, experiências...",
        "from": "de"
    }
    // mais traduções...
  };
